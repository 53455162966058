import React from 'react';
import Layout from '../../components/layout';
import { Link } from 'gatsby';

const Page = () => (
  <Layout>
    <main>
      <h1>picks</h1>
      <p>
        Here's a list of lists of things I happen to find particularly
        interesting and/or helpful:
      </p>
      <h2>
        <Link to="/picks/newsletters">Newsletters</Link>
      </h2>
      <h2>
        <Link to="/picks/podcasts">Podcasts</Link>
      </h2>
      <h2>
        <Link to="/picks/software">Software &amp; misc. dev tools</Link>
      </h2>
      <h2>
        <Link to="/picks/books">Books</Link>
      </h2>
    </main>
  </Layout>
);

export default Page;
